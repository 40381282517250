/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, FC, PropsWithChildren } from "react";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import getNewAccesToken from "../../services/auth/getNewAccessToken";
import getStorageTokens from "../../helpers/getStorageTokens";
import SessionContext, {
    ISession,
    IUser,
    TSaveSession,
    IConnUser,
} from "./SessionContext";
import { useAuth, hasAuthParams } from "react-oidc-context";

/**
 * Interfaz necesaria para definir el objecto de configuracion
 * en el interceptors.response de la instancia de axios
 */
export interface AxiosRequestConfigCustom extends AxiosRequestConfig {
    _retry?: boolean;
}

/**
 * Envuelve y proporciona un estado global de toda la session
 * del usuario y la instancia de privateAxios con los tokens
 * de autenticacion
 * @param {PropsWithChildren} props
 * @returns
 */
const SessionProvider: FC<PropsWithChildren> = ({ children }) => {
    const auth = useAuth();
    const [showAccountAlert, setShowAccountAlert] = useState(false);
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    /**
     * Contiene la informacion del usuario tras hacer login (email, password)
     */
    const [user, setUser] = useState<IUser | null>(null);

    /**
     * Conexion actual selecionada
     */
    const [connId, setConnId] = useState<IConnUser>(() => {
        const connctionId = sessionStorage.getItem("connId");
        return connctionId
            ? (JSON.parse(connctionId) as IConnUser)
            : ({} as IConnUser);
    });

    /**
     * Dashboard actual selecionado
     */
    const [dashboardId, setDashboardId] = useState<string>(() => {
        const dashId = sessionStorage.getItem("dashboardId");
        return dashId ? (JSON.parse(dashId) as string) : ({} as string);
    });

    /**
     * Contiene los tokens de autenticacion tras hacer
     * login (access_token, refresh_token)
     */
    const storageTokens = getStorageTokens();
    const [session, setSession] = useState<ISession | null>(storageTokens);

    /**
     * Estado para comprobar si se recuerda la sesion o no
     */
    const saveSessionStorage = localStorage.getItem("saveSession");
    const [isSaveSession, setIsSaveSession] = useState<TSaveSession>(
        saveSessionStorage ? JSON.parse(saveSessionStorage) : undefined
    );

    /**
     * Guarda el token y el refresh token en el
     * local storage cada vez que el estado (session)
     * cambia
     */
    useEffect(() => {
        console.log("isSaveSession: ", isSaveSession);
        if (isSaveSession !== undefined) {
            console.log("se guarda el token en localStorage");
            try {
                localStorage.setItem("tokens", JSON.stringify(session));
            } catch (error) {
                localStorage.removeItem("tokens");
            }
        }

        try {
            console.log("se guarda el token en sessionStorage");
            sessionStorage.setItem("tokens", JSON.stringify(session));
        } catch (error) {
            sessionStorage.removeItem("tokens");
        }
    }, [session, isSaveSession]);

    /**
     * Guarda el estado saveSession en el local storage
     * cada vez que el estado cambia
     */
    useEffect(() => {
        try {
            if (isSaveSession) {
                localStorage.setItem(
                    "saveSession",
                    JSON.stringify(isSaveSession)
                );
            } else {
                localStorage.removeItem("saveSession");
            }
        } catch (error) {
            console.log(error);
        }
    }, [isSaveSession]);

    /**
     * Persiste la conexion en sessionStorage
     */
    useEffect(() => {
        sessionStorage.setItem("dashboardId", JSON.stringify(dashboardId));
    }, [dashboardId]);

    /**
     * Persiste el dashboard id en sessionStorage
     */
    useEffect(() => {
        sessionStorage.setItem("connId", JSON.stringify(connId));
    }, [connId]);

    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinPopup();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    useEffect(() => {
        const hasEmailAndType = connId.email && connId.type === "Formsly";
        if (auth.isAuthenticated && auth.user && hasEmailAndType) {
            const isSameAccount = auth.user.profile.email === connId.email;
            setShowAccountAlert(!isSameAccount);
        } else if (!auth.isAuthenticated && connId.type === "Formsly") {
            setShowAccountAlert(true);
        } else {
            setShowAccountAlert(false);
        }
    }, [auth, connId]);

    /**
     * Deslogea al usuario removiendo los tokens del
     * localstorage y del estado global (session)
     */
    const logout = () => {
        try {
            setSession(null);
            localStorage.removeItem("tokens");
            localStorage.removeItem("saveSession");
            sessionStorage.removeItem("session");
        } catch (error) {
            console.log(error);
        }
    };
    /**
     * Verifica si el estado (session) contiene
     * algun token
     * @returns {Boolean}
     */
    const isAuth = (): boolean => {
        return session !== null;
    };

    const privateAxios: AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    /**
     * Intercepta cada request e inserta el access token
     */
    const requestIntercept = privateAxios.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            if (!config?.headers?.Athorization) {
                config.headers!.Authorization = `Bearer ${
                    session?.access_token as string
                }`;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    /**
     * En caso de que el access token sea invalido
     * hara una peticion al backend para obtener un nuevo token
     */

    const responseIntercept = privateAxios.interceptors.response.use(
        (response) => response,
        async (error: AxiosError) => {
            const originalConfig: AxiosRequestConfigCustom = error.config;
            if (error.response) {
                if (error.response.status === 401 && !originalConfig._retry) {
                    try {
                        const newTokens = await getNewAccesToken(session!);

                        if (!newTokens.error) {
                            originalConfig._retry = true;
                            setSession(newTokens.res as ISession);
                            originalConfig.headers!.Authorization = `Bearer ${
                                newTokens.res?.access_token as string
                            }`;
                        }
                        return privateAxios(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(error);
        }
    );

    return (
        <SessionContext.Provider
            value={{
                user,
                setUser,
                session,
                setSession,
                isSaveSession,
                setIsSaveSession,
                logout,
                isAuth,
                privateAxios,
                setConnId,
                connId,
                dashboardId,
                setDashboardId,
                showAccountAlert,
                setShowAccountAlert,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

export default SessionProvider;
