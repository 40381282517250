import { createContext } from "react";
import axios, { AxiosInstance } from "axios";

export interface IUser {
    email?: string;
    password?: string;
    googleId?: string;
}

export interface ISession {
    access_token: string;
    refresh_token: string;
}

export interface IConnUser {
    id: string;
    email: string;
    type: string;
}

export interface IDashboardId {
    uuid: string;
}

export type TSaveSession = boolean;

export interface ISessionContext {
    user: IUser | null;
    setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
    session: ISession | null;
    setSession: React.Dispatch<React.SetStateAction<ISession | null>>;
    isSaveSession: TSaveSession | null;
    setIsSaveSession: React.Dispatch<React.SetStateAction<boolean>>;
    logout: () => void;
    isAuth: () => boolean;
    privateAxios: AxiosInstance;
    connId: IConnUser;
    setConnId: React.Dispatch<React.SetStateAction<IConnUser>>;
    setDashboardId: React.Dispatch<React.SetStateAction<string>>;
    dashboardId: string;
    setShowAccountAlert: React.Dispatch<React.SetStateAction<boolean>>;
    showAccountAlert: boolean;
}

const initalState = {
    user: null,
    setUser: () => {},
    session: null,
    setSession: () => {},
    isSaveSession: null,
    setIsSaveSession: () => {},
    logout: () => {},
    isAuth: () => true,
    privateAxios: axios,
    setConnId: () => {},
    connId: {} as IConnUser,
    setDashboardId: () => {},
    dashboardId: "",
    setShowAccountAlert: () => {},
    showAccountAlert: false,
};

const SessionContext = createContext<ISessionContext>(initalState);

export default SessionContext;
